<template>
    <div class="login-container d-flex align-items-center">
        <div class="form-login">
            <form @submit.prevent="login()">
                <p class="text-muted">Welcome Back</p>
                <h3 class="fw-bold text-black mb-4">Sign In</h3>
                <div class="textBox-width">
                    <span class="p-float-label">
                        <InputText
                            id="email"
                            type="text"
                            class="p-inputtext w-100"
                            v-model="v$.email.$model"
                            @input="onEmailInput"
                        />
                        <label for="email">Email</label>
                    </span>
                    <span
                        v-if="v$.email.$error && (v$.email.required && v$.email.required.$invalid)"
                        class="text-danger">{{messages.registrationValidation.emailRequired}}
                    </span>
                    <span
                        v-else-if="v$.email.$error && (v$.email.email && v$.email.email.$invalid)"
                        class="text-danger">{{messages.registrationValidation.emailInvalid}}
                    </span>
                    <span v-if="errorMessage" class="text-danger">{{ errorMessage }}</span>
                </div>
                <div class="textBox-width mt-4">
                    <span class="p-float-label">
                        <InputText
                            id="password"
                            type="password"
                            class="p-inputtext w-100"
                            v-model="v$.password.$model"
                        />
                        <label for="password">Password</label>
                    </span>
                    <span
                        v-if="v$.password.$error && (v$.password.passwordRequired && v$.password.passwordRequired.$invalid)"
                        class="text-danger">{{messages.registrationValidation.passwordRequired}}
                    </span>
                </div>
                <p class="text-end textBox-width">
                    <router-link to="/forgotpassword" class="size14 fw-normal">Forgot Password</router-link>
                </p>
                <Button
                    class="lavender-purple-background justify-content-center textBox-width"
                    type="submit"
                    ><span class="size18 fw-normal">SIGN IN</span></Button
                >
                <div class="text-end mt-4 textBox-width">
                    <h5 class="text-muted fs-6 fw-normal">New to IIQ? <router-link to="/register">Register</router-link></h5>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import useVuelidate from '@vuelidate/core';
import { required, email } from '@vuelidate/validators';
import { passwordRequired } from '../utils/validation-utils';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import { login, web, messages } from '../constants/constants';
import { AuthService, UserService } from '../../apis';
import { Utility } from '../utils';
export default {
    setup: () => ({ v$: useVuelidate() }),
    name: 'Login',
    components: {
        InputText,
        Button
    },
    data() {
        return {
            email: '',
            password: '',
            errorMessage: '',
            messages
        };
    },
    validations() {
        return {
            email: { required, email },
            password: { passwordRequired }
        };
    },
    methods: {
        async login() {
            const isFormValid = await this.v$.$validate();
            if (!isFormValid) {
                return;
            }
            this.email = this.email.trim().toLowerCase();
            AuthService.login(this.email, this.password).then(
                user => {
                    this.$router.replace(decodeURIComponent(this.$route.query.redirect || this.$store.getters.landingPage));
                    UserService.updateLoginLogoutDateTime(
                        {
                            when: new Date(),
                            what: login,
                            where: web,
                            using: navigator.userAgent
                        },
                        user.user.uid
                    );
                    Utility.removeKeysFromLocalStorage();
                },
                err => {
                    this.errorMessage = messages.loginValidation.invalidLoginCredential;
                }
            );
        },
        onEmailInput(e) {
            this.errorMessage = '';
            this.email = e.target.value = e.target.value.trim().toLowerCase();
        }
    }
};
</script>
